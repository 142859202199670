<template>
  <div class="pagination">
    <div class="pagination-prev" :class="{ 'disabled': isFirstPage }" @click="changeOnMobile(currentPage - 1)" rel="prev">
      <a :disabled="{ 'disabled': isFirstPage }"></a>
    </div>
    <div class="pagination-small__body">
      Page&emsp;
      <input id="pagination-input" type="number" inputmode="numeric" v-model.number="currentPage" @change="changeInputOnMobile" />
       / {{ totalPages }}
    </div>
    <div class="pagination-next" :class="{ 'disabled': isLastPage }" @click="changeOnMobile(currentPage + 1)" rel="next">
      <a :disabled="{ 'disabled': isLastPage }"></a>
    </div>
  </div>
</template>

<script>
/* eslint max-len: 0 */

export default {
  props: {
    value: { // $emit payload from uib-pagination (currentPage, numPages)
      type: Object,
    },
    totalItems: Number,
    itemsPerPage: Number,
  },
  data() {
    return {
      currentPage: this.value.currentPage ? this.value.currentPage : 1,
    };
  },
  methods: {
    changeOnMobile(targetPage) {
      // send signal to parent
      this.$emit('changeOnMobile', targetPage);
    },
    changeInputOnMobile() {
      // currently in browser @change function is triggered only when input is out of focus, or on enter
      let toPage = 0;
      if (this.currentPage < 1) {
        // if the page entered is < 1, reset currentPage and show the first page
        this.currentPage = 1;
        toPage = 1;
      } else if (this.currentPage > this.totalPages) {
        // if the page entered is > total pages, reset currentPage and show the last page
        this.currentPage = this.totalPages;
        toPage = this.totalPages;
      } else {
        toPage = this.currentPage;
      }
      // send signal to parent
      this.$emit('changeOnMobile', toPage);
    },
  },
  computed: {
    totalPages() {
      return this.value.numPages;
    },
    isFirstPage() {
      return this.value.currentPage === 1;
    },
    isLastPage() {
      return this.value.currentPage === this.totalPages;
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
