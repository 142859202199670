<i18n>
{
  "en": {
    "browser_title_newest": "Newest Movies",
    "browser_title_oldest": "Oldest Movies",
    "browser_title_monthly": "Monthly Ranked Movies",
    "browser_title_weekly": "Weekly Ranked Movies",
    "browser_title_bob": "Popular Movies",
    "browser_title_random": "Random Movies",
    "browser_title_vip": "VIP Movies",
    "browser_title_svip": "SVIP Movies",
    "browser_title_annual": "Annual Movies",
    "browser_title_year_rel": " Release Year"
  },
  "ja": {
    "browser_title_newest": "新着順（新⇒古）",
    "browser_title_oldest": "配信順（古⇒新）",
    "browser_title_monthly": "過去1ヶ月の人気順",
    "browser_title_weekly": "過去1週間の人気順",
    "browser_title_bob": "よく再生される動画",
    "browser_title_random": "よく再生される動画",
    "browser_title_vip": "VIP動画",
    "browser_title_svip": "超VIP動画",
    "browser_title_annual": "年間動画",
    "browser_title_year_rel": "年配信"
  }
}
</i18n>

<template>
<main>
  <vue-headful v-bind:title="dynamicTitle" />
  <movie-list :list-size="movieListSize"></movie-list>
</main>
</template>

<script>
/* eslint max-len: 0 */

import startCase from 'lodash/startCase';
import findIndex from 'lodash/findIndex';
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import SiteConfig from '@/assets/js/utils/SiteConfig';
import Analytics from '@/assets/js/utils/Analytics';
import MovieList from '@/components/movieList/movieList.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    'movie-list': MovieList,
  },
  data() {
    return {
      config: {},
      dynamicTitle: '',
    };
  },
  async created() {
    // set browser title
    if (this.$route.name === 'movie-list') {
      // sorted movie list
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'o')) {
        switch (this.$route.query.o) {
          case 'newest':
            this.dynamicTitle = `${this.$t('browser_title_newest')}`;
            break;
          case 'oldest':
            this.dynamicTitle = `${this.$t('browser_title_oldest')}`;
            break;
          case 'monthly':
            this.dynamicTitle = `${this.$t('browser_title_monthly')}`;
            break;
          case 'weekly':
            this.dynamicTitle = `${this.$t('browser_title_weekly')}`;
            break;
          case 'bob':
            this.dynamicTitle = `${this.$t('browser_title_bob')}`;
            break;
          case 'random':
            this.dynamicTitle = `${this.$t('browser_title_random')}`;
            break;
          default:
            this.dynamicTitle = `${this.$t('browser_title_newest')}`;
            break;
        }
        // append site name to end of title
        this.dynamicTitle += ` ${APP_CONFIG.site.title[this.locale]}`;

        // we have the title, now send the page view
        this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
      }
    } else if (this.$route.name === 'movie-search') {
      // search results movie list
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'c')) {
        // categories list
        const bfAPI = new BifrostAPI();
        bfAPI.getCategories().then((result) => {
          const categoryMap = Object.assign({}, APP_CONFIG.categories, result);
          const selectedCategories = this.$route.query.c.split(',');

          // add each category name to the title
          for (let i = 0; i < selectedCategories.length; i += 1) {
            if (Object.prototype.hasOwnProperty.call(categoryMap, selectedCategories[i])) {
              this.dynamicTitle += `${categoryMap[selectedCategories[i]].name[this.locale]} `;
            }
          }

          // append site name to end of title
          this.dynamicTitle += ` ${APP_CONFIG.site.title[this.locale]}`;

          // we have the title, now send the page view
          this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
        });
      } else if (Object.prototype.hasOwnProperty.call(this.$route.query, 'sr')) {
        // series list
        const bfAPI = new BifrostAPI();
        bfAPI.getSeries().then((result) => {
          const seriesMap = result;

          // set title
          this.dynamicTitle = `${seriesMap[this.$route.query.sr].name[this.locale]} ${APP_CONFIG.site.title[this.locale]}`;

          // we have the title, now send the page view
          this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
        });
      } else if (Object.prototype.hasOwnProperty.call(this.$route.query, 'a')) {
        // actress list
        const bfAPI = new BifrostAPI();
        bfAPI.getMovieListActress(0, 1, this.$route.query.a).then((result) => {
          // find index of actress in Actress data (why? because some movies have more than 1 actress)
          const actressIdx = findIndex(result.Rows[0].ActorID, item => item === parseInt(this.$route.query.a, 10));

          // set title - actress names are keyed as 'ActressesJa', and 'ActressesEn', so capitalize 1st letter of locale
          const actressKey = `Actresses${startCase(this.locale)}`;
          this.dynamicTitle = `${result.Rows[0][actressKey][actressIdx]} ${APP_CONFIG.site.title[this.locale]}`;

          // we have the title, now send the page view
          this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
        });
      } else if (Object.prototype.hasOwnProperty.call(this.$route.query, 'mt')) {
        // movie type list
        switch (this.$route.query.mt) {
          case '1':
            this.dynamicTitle = `${this.$t('browser_title_vip')}`;
            break;
          case '2':
            this.dynamicTitle = `${this.$t('browser_title_svip')}`;
            break;
          default:
            break;
        }
        // append site name to end of title
        this.dynamicTitle += ` ${APP_CONFIG.site.title[this.locale]}`;

        // we have the title, now send the page view
        this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
      } else if (Object.prototype.hasOwnProperty.call(this.$route.query, 'mc')) {
        // movie conditions list
        switch (this.$route.query.mc) {
          case '5':
            this.dynamicTitle = `${this.$t('browser_title_annual')}`;
            break;
          default:
            break;
        }
        // append site name to end of title
        this.dynamicTitle += ` ${APP_CONFIG.site.title[this.locale]}`;

        // we have the title, now send the page view
        this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
      } else if (Object.prototype.hasOwnProperty.call(this.$route.query, 'my')) {
        // movie year released list
        this.dynamicTitle = `${this.$route.query.my}${this.$t('browser_title_year_rel')}`;

        // append site name to end of title
        this.dynamicTitle += ` ${APP_CONFIG.site.title[this.locale]}`;

        // we have the title, now send the page view
        this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
      } else if (Object.prototype.hasOwnProperty.call(this.$route.query, 's')) {
        // text search
        Analytics.sendCustomEvent('search', { search_term: this.$route.query.s });
      }
    }

    // get site config (settings from admin)
    this.config = await SiteConfig.getConfig('movieList');
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    movieListSize() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'moviesPerPage') ? this.config.moviesPerPage : 24);
    },
  },
};
</script>

<style lang="scss">
</style>
