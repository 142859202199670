<i18n>
{
  "en": {
    "list_all": "All Movies",
    "search_results": "Search Results",
    "search_results_series_0": "Series '",
    "search_results_series_1": "'",
    "search_results_fts_0": "Search Results for '",
    "search_results_fts_1": "'",
    "actress_results_0": "'",
    "actress_results_1": "' Appearances",
    "year_results": " Release Year",
    "filter": "Filter",
    "sort_label": "Sort Order",
    "sort_newest": "Newest",
    "sort_oldest": "Oldest",
    "sort_popular": "Popular",
    "movie_status": "Status",
    "status_vip": "VIP",
    "status_svip": "SVIP",
    "status_annual": "Annual",
    "movie_type": "Type",
    "movie_year_released": "Year Released"
  },
  "ja": {
    "list_all": "作品一覧",
    "search_results": "検索結果",
    "search_results_series_0": "「",
    "search_results_series_1": "」シリーズ",
    "search_results_fts_0": "「",
    "search_results_fts_1": "」の検索結果",
    "actress_results_0": "「",
    "actress_results_1": "」 出演",
    "year_results": "年配信",
    "filter": "フィルタ",
    "sort_label": "並べ替え",
    "sort_newest": "新着順",
    "sort_oldest": "古い順",
    "sort_popular": "人気順",
    "movie_status": "配信ステータス",
    "status_vip": "VIP動画",
    "status_svip": "超VIP動画",
    "status_annual": "年間動画",
    "movie_type": "動画タイプ",
    "movie_year_released": "配信年"
  }
}
</i18n>

<template>
<div class="search-sub-menu">
  <div class="search-sub-menu__header">
    <h1 class="h1-dense">
      <!-- list header: default -->
      <span v-if="$route.name === 'movie-list'">{{ $t('list_all') }}</span>
      <!-- list header: category -->
      <span v-if="$route.name === 'movie-search' && $route.query.c">{{ $t('search_results_fts_0') }}{{ selectedCategoryNames }}{{ $t('search_results_fts_1') }}</span>
      <!-- list header: movie type -->
      <span v-if="$route.name === 'movie-search' && $route.query.mt">{{ selectedMovieType }}</span>
      <!-- list header: movie condition -->
      <span v-if="$route.name === 'movie-search' && $route.query.mc">{{ selectedMovieCondition }}</span>
      <!-- list header: text search results -->
      <span v-if="$route.name === 'movie-search' && $route.query.s">{{ $t('search_results_fts_0') }}{{ $route.query.s }}{{ $t('search_results_fts_1') }}</span>
      <!-- list header: series list -->
      <span v-if="$route.name === 'movie-search' && $route.query.sr && seriesMap[$route.query.sr]">{{ $t('search_results_series_0') }}{{ seriesMap[$route.query.sr].name[locale] }}{{ $t('search_results_series_1') }}</span>
      <!-- list header: actress list -->
      <span v-if="$route.name === 'movie-search' && $route.query.a">{{ $t('actress_results_0') }}{{ selectedActress }}{{ $t('actress_results_1') }}</span>
      <!-- list header: year list -->
      <span v-if="$route.name === 'movie-search' && $route.query.my">{{ $route.query.my }}{{ $t('year_results') }}</span>

      <!-- list count + current location -->
      <span class="search-count" v-if="locale == 'ja'">({{ totalMovies }}件中 {{ offset + 1 }}-{{ (offset + listSize) > totalMovies ? totalMovies : offset + listSize }}件)</span>
      <span class="search-count" v-if="locale == 'en'">({{ offset + 1 }}-{{ (offset + listSize) > totalMovies ? totalMovies : offset + listSize }} of {{ totalMovies }})</span>
    </h1>

    <!-- search submenu -->
    <button class="search-sub-menu__toggle button-flat button-icon--right button-large" @click="submenuToggle()"><label for="search-sub-menu__contents">{{ $t('filter') }}<svg class="tm-icon"><use xlink:href="#tm-icon-arrow-drop-down"/></svg></label></button>
  </div>
  <transition name="filter-menu">
    <div class="search-sub-menu__contents" v-if="showFilterMenu">
      <div class="search-sub-menu__section">
        <p>{{ $t('sort_label') }}</p>
        <label><input type="radio" name="sort" class="radio-button" value="newest" v-model="sortType" @change="updateListSort()"><span class="tm-radio-button"></span><span class="search-sub-menu__item">{{ $t('sort_newest') }}</span></label>
        <label><input type="radio" name="sort" class="radio-button" value="oldest" v-model="sortType" @change="updateListSort()"><span class="tm-radio-button"></span><span class="search-sub-menu__item">{{ $t('sort_oldest') }}</span></label>
        <label><input type="radio" name="sort" class="radio-button" value="bob" v-model="sortType" @change="updateListSort()"><span class="tm-radio-button"></span><span class="search-sub-menu__item">{{ $t('sort_popular') }}</span></label>
      </div>
      <div class="search-sub-menu__section" v-if="!newXPEligible">
        <p>{{ $t('movie_status') }}</p>
        <label for="50000" @change="checkboxClicked(50000)"><input type="checkbox" name="status" class="checkbox" id="50000" value="50000" v-model="selectedCategories"><span class="tm-checkbox"></span><span class="search-sub-menu__item">{{ $t('status_vip') }}</span></label>
        <label for="50001" @change="checkboxClicked(50001)"><input type="checkbox" name="status" class="checkbox" id="50001" value="50001" v-model="selectedCategories"><span class="tm-checkbox"></span><span class="search-sub-menu__item">{{ $t('status_svip') }}</span></label>
        <label for="50003" @change="checkboxClicked(50003)"><input type="checkbox" name="status" class="checkbox" id="50003" value="50003" v-model="selectedCategories"><span class="tm-checkbox"></span><span class="search-sub-menu__item">{{ $t('status_annual') }}</span></label>
        <!-- <label for="50002" @change="checkboxClicked(50002)"><input type="checkbox" name="status" class="checkbox" id="50002" value="50002" v-model="selectedCategories"><span class="tm-checkbox"></span><span class="search-sub-menu__item">期間限定</span></label> -->
      </div>
      <div class="search-sub-menu__section">
        <p>{{ $t('movie_type') }}</p>
        <span v-if="showVRFilter"><label :for="`${vrCategoryID}`" @change="checkboxClicked(vrCategoryID)"><input type="checkbox" name="type" class="checkbox" :id="`${vrCategoryID}`" :value="`${vrCategoryID}`" v-model="selectedCategories"><span class="tm-checkbox"></span><span class="search-sub-menu__item">VR</span></label></span>
        <label for="60000" @change="checkboxClicked(60000)"><input type="checkbox" name="type" class="checkbox" id="60000" value="60000" v-model="selectedCategories"><span class="tm-checkbox"></span><span class="search-sub-menu__item">Full HD</span></label>
        <label for="60002" @change="checkboxClicked(60002)"><input type="checkbox" name="type" class="checkbox" id="60002" value="60002" v-model="selectedCategories"><span class="tm-checkbox"></span><span class="search-sub-menu__item">HD</span></label>
        <label for="60001" @change="checkboxClicked(60001)"><input type="checkbox" name="type" class="checkbox" id="60001" value="60001" v-model="selectedCategories"><span class="tm-checkbox"></span><span class="search-sub-menu__item">60fps</span></label>
      </div>
      <div class="search-sub-menu__section">
        <p>{{ $t('movie_year_released') }}</p>
        <select v-model="yearSelected" @change="updateListByYear()" class="year-dropdown">
          <option v-for="year in yearRange" :key="year" :value="year">{{ year }}</option>
        </select>
      </div>
      <!--
      <div class="search-sub-menu__section">
        <p>配信日</p>
        <label><input type="checkbox" name="release-date" class="checkbox"><span class="tm-checkbox"></span><span class="search-sub-menu__item">過去7日間</span></label>
        <label><input type="checkbox" name="release-date" class="checkbox"><span class="tm-checkbox"></span><span class="search-sub-menu__item">過去30日間</span></label>
        <label><input type="checkbox" name="release-date" class="checkbox"><span class="tm-checkbox"></span><span class="search-sub-menu__item">過去1年間</span></label>
      </div>
      -->
    </div>
  </transition>
</div>
</template>

<script>
/* eslint max-len: 0 */

import range from 'lodash/range';
import keyBy from 'lodash/keyBy';
import findIndex from 'lodash/findIndex';
import startCase from 'lodash/startCase';
import remove from 'lodash/remove';
import APP_CONFIG from '@/appConfig';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

const VR_CATEGORY_ID = 94;

export default {
  props: {
    totalMovies: {
      type: Number,
      required: true,
    },
    offset: {
      type: Number,
      required: true,
    },
    listSize: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      sortType: 'newest',
      categories: {},
      categoriesList: [],
      selectedCategories: [],
      categoryMap: {},
      seriesMap: {},
      selectedActress: '',
      showFilterMenu: false,
      selectedCategoryNames: '',
      selectedMovieType: '',
      selectedMovieCondition: '',
      yearSelected: this.$route.query.my || (new Date()).getFullYear(),
    };
  },
  created() {
    // non-reactive consts
    this.vrCategoryID = VR_CATEGORY_ID;
    this.currentYear = (new Date()).getFullYear();
    this.yearRange = range(2003, (new Date()).getFullYear() + 1).reverse();

    this.sortType = this.$route.query.o;

    const bfAPI = new BifrostAPI();

    // get categories info for category queries
    bfAPI.getCategories().then((result) => {
      this.categories = result;
      this.categoryMap = Object.assign({}, APP_CONFIG.categories, result);

      // get selected categories
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'c')) {
        this.selectedCategories = this.$route.query.c.split(',');

        // add each category name to the header
        for (let i = 0; i < this.selectedCategories.length; i += 1) {
          if (Object.prototype.hasOwnProperty.call(this.categoryMap, this.selectedCategories[i])) {
            this.selectedCategoryNames += `${this.categoryMap[this.selectedCategories[i]].name[this.locale]} `;
          }
        }
      }
    });

    // get series info for series queries
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'sr')) {
      bfAPI.getSeries().then((result) => {
        this.seriesList = result;

        // build a series map
        this.seriesMap = keyBy(this.seriesList, 'series_id');
      });
    }

    // get actress info for actress queries
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'a')) {
      bfAPI.getMovieListActress(0, 1, this.$route.query.a).then((result) => {
        // find index of actress in Actress data (why? because some movies have more than 1 actress)
        const actressIdx = findIndex(result.Rows[0].ActorID, item => item === parseInt(this.$route.query.a, 10));

        // set title - actress names are keyed as 'ActressesJa', and 'ActressesEn', so capitalize 1st letter of locale
        const actressKey = `Actresses${startCase(this.locale)}`;
        this.selectedActress = result.Rows[0][actressKey][actressIdx];
      });
    }

    // get movie type header text
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'mt')) {
      switch (this.$route.query.mt) {
        case '1':
          this.selectedMovieType = this.$t('status_vip');
          break;
        case '2':
          this.selectedMovieType = this.$t('status_svip');
          break;
        default:
          this.selectedMovieType = this.$t('search_results');
          break;
      }
    }

    // get movie condition header text
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'mc')) {
      switch (this.$route.query.mc) {
        case '5':
          this.selectedMovieCondition = this.$t('status_annual');
          break;
        default:
          this.selectedMovieCondition = this.$t('search_results');
          break;
      }
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    showVRFilter() {
      // show VR filter option if VR movies exist AND if we are in the classic experience
      // OR
      // if VR movies exist AND we are in the new experence and the config to hide VR is disabled
      return (Object.prototype.hasOwnProperty.call(this.categories, VR_CATEGORY_ID)
        && this.categories[VR_CATEGORY_ID].movie_count > 0
        && (!this.newXPEligible
          || (this.newXPEligible && process.env.VUE_APP_NEW_XP_HIDE_VR === 'false')));
    },
  },
  methods: {
    removeCategory(UC) {
      this.selectedCategories = remove(this.selectedCategories, n => n !== UC);
      if (this.selectedCategories.length > 0) {
        // categories have been selected, go to /search/
        this.$router.push({ name: 'movie-search', query: { c: this.selectedCategories.join(',') } });
      } else {
        // no categories selected (or the only selected category has been unchecked), go to new list
        this.$router.push({ name: 'movie-list', query: { page: 1, o: 'newest' } });
      }
    },
    updateListSort() {
      this.$router.push({ name: 'movie-list', query: { page: 1, o: this.sortType } });
    },
    updateListByYear() {
      this.$router.push({ name: 'movie-search', query: { page: 1, my: this.yearSelected } });
    },
    checkboxClicked(UC) {
      this.$analytics.trackEvent('Sidebar Category', this.categoryMap[UC].name.ja, UC);

      if (this.selectedCategories.length > 0) {
        // categories have been selected, go to /search/
        this.$router.push({ name: 'movie-search', query: { c: this.selectedCategories.join(',') } });
      } else {
        // no categories selected (or the only selected category has been unchecked), go to new list
        this.$router.push({ name: 'movie-list', query: { page: 1, o: 'newest' } });
      }
    },
    submenuToggle() {
      this.showFilterMenu = !this.showFilterMenu;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-menu-enter-active, .filter-menu-leave-active {
  transition: opacity .5s;
}
.filter-menu-enter, .filter-menu-leave-to {
  opacity: 0;
}
</style>
