<i18n>
{
  "en": {
  },
  "ja": {
  }
}
</i18n>

<template>
<div class="contents">
  <list-banner />

  <movie-list-header :totalMovies="totalMovies" :offset="offset" :listSize="listSize" />
  <div class="list list--default">
    <div class="flex-grid">
      <!-- list item -->
      <movie-thumbnail
        v-for="movie in movieList"
        :key="movie.MovieID"
        :movie="movie"
        theme="list" />
      <!-- /list item -->
    </div>
  </div><!-- /.list -->

  <div class="pagination-large section">
    <uib-pagination
      v-model="pagination"
      :boundary-links="false"
      :boundary-link-numbers="true"
      :force-ellipses="true"
      :max-size="10"
      first-text=""
      last-text=""
      previous-text=""
      next-text=""
      :total-items="totalMovies"
      :items-per-page="listSize"
      @change="pageChanged()" />
  </div>

  <div class="pagination-small section">
    <mobile-pagination
      v-model="pagination"
      :total-items="totalMovies"
      :items-per-page="listSize"
      @changeOnMobile="mobilePageChanged" />
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
import Deferred from '@/assets/js/utils/Deferred';
import MovieHelper from '@/assets/js/utils/movie';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import Analytics from '@/assets/js/utils/Analytics';
import EventBus from '@/assets/js/utils/EventBus';
import ListBanner from '@/components/movieList/listBanner.vue';
import MovieListHeader from './movieListHeader.vue';
import MobilePagination from './mobilePagination.vue';
import movieThumbnail from '@/components/_common/movieThumbnail.vue';

const bfAPI = new BifrostAPI();

export default {
  props: {
    listSize: Number,
  },
  components: {
    'list-banner': ListBanner,
    'movie-list-header': MovieListHeader,
    'mobile-pagination': MobilePagination,
    'movie-thumbnail': movieThumbnail,
  },
  data() {
    return {
      movieList: [],
      totalMovies: 0,
      queryParams: {},
      pagination: { currentPage: Number(this.$route.query.page) || 1 },
      posterFallbackTries: {},
      hoverId: '',
    };
  },
  created() {
    // non-reactive consts
    this.watchLaterPlaylistName = 'watch_later';

    // fetch movie list
    this.queryParams = this.getQueryParams();
    this.getMovieListForUser(this.userTypeID);
  },
  methods: {
    getQueryParams() {
      const queryParams = {
        // page: (this.$route.query.page) ? this.$route.query.page : 1,
        // size: this.listSize,
      };

      // all query params that this needs to care about (to keep around when the location changes)
      // o: sort preference (newest, oldest, etc)
      // s: FTS search string
      // c: category id[s]
      // a: actress id
      // am: actor id
      // mt: movie type
      // mc: movie conditions
      // ml: movie list (named)
      // sr: series id
      // my: movie year
      // cl: custom list (manually created list of movie ids)
      const MOVIE_LIST_PARAMS = [
        'o',
        's',
        'c',
        'a',
        'am',
        'mt',
        'mc',
        'ml',
        'sr',
        'my',
        'cl',
      ];

      for (let i = 0; i < MOVIE_LIST_PARAMS.length; i += 1) {
        if (this.$route.query[MOVIE_LIST_PARAMS[i]]) {
          queryParams[MOVIE_LIST_PARAMS[i]] = this.$route.query[MOVIE_LIST_PARAMS[i]];
        }
      }

      return queryParams;
    },

    getMovieListForUser(userTypeID) {
      this.getMovieList().then((result) => {
        this.totalMovies = result.TotalRows;
        const movieList = MovieHelper.expandDetailsList(result.Rows, this.locale, userTypeID);
        this.movieList = MovieHelper.filterExcludedMovieList(movieList, userTypeID);
      });
    },

    getMovieList() {
      const defer = new Deferred();

      if (!Object.prototype.hasOwnProperty.call(this.queryParams, 'o')) {
        if (Object.prototype.hasOwnProperty.call(this.queryParams, 'c')) {
          // category results
          const categoryIDs = this.queryParams.c.split(',').map(x => parseInt(x, 10));
          bfAPI.getCategoryList(this.offset, this.listSize, categoryIDs).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 's')) {
          // text search results
          bfAPI.getFTS(this.queryParams.s, this.offset, this.listSize).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'a')) {
          // actress search results
          bfAPI.getMovieListActress(this.offset, this.listSize, this.queryParams.a).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'am')) {
          // actor search results
          bfAPI.getMovieListActor(this.offset, this.listSize, this.queryParams.am).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'mt')) {
          // movie type results
          bfAPI.getMovieListType(this.offset, this.listSize, this.queryParams.mt).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'mc')) {
          // movie conditions results
          bfAPI.getMovieListCondition(this.offset, this.listSize, this.queryParams.mc).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'ml')) {
          // movie list results
          bfAPI.getMovieListNamed(this.offset, this.listSize, this.queryParams.ml).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'sr')) {
          // movie conditions results
          bfAPI.getMovieListSeries(this.offset, this.listSize, this.queryParams.sr).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'my')) {
          // movies by year results
          bfAPI.getMovieListByYear(this.offset, this.listSize, this.queryParams.my).then((result) => {
            defer.resolve(result);
          });
        } else {
          // default if no params: show newest list
          bfAPI.getMovieListNewest(this.offset, this.listSize).then((result) => {
            defer.resolve(result);
          });
        }
      } else {
        // sorted list
        switch (this.queryParams.o) {
          case 'newest': {
            bfAPI.getMovieListNewest(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
          case 'oldest': {
            bfAPI.getMovieListOldest(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
          case 'monthly': {
            bfAPI.getMovieListMonthly(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
          case 'weekly': {
            bfAPI.getMovieListWeekly(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
          case 'bob': {
            bfAPI.getMovieListBOB(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
          case 'random': {
            // note: we send a 'seed' here (initially null) which is sent from the backend. this
            // keeps the "randomness" list consistent for the same request (in other words, the
            // entire list is randomized based on the seed so that we don't get duplinpm cate movies
            // appearing on different pages)
            bfAPI.getMovieListRandom(this.$store.getters['bifrost/randomSeed'], this.offset, this.listSize).then((result) => {
              this.$store.dispatch('bifrost/setRandomSeed', result.Seed);
              defer.resolve(result);
            });
            break;
          }
          case '6zQep9xnIMZM': {
            // reserved for SEO
            bfAPI.getMovieListSEOTemplates(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
          default: {
            bfAPI.getMovieListNewest(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
        }
      }
      return defer.promise;
    },

    pageChanged() {
      this.$router.push({ name: this.$router.name, query: Object.assign({ page: this.pagination.currentPage }, this.queryParams) });
    },

    mobilePageChanged(targetPage) {
      // received $emit signal from mobile-pagination and route to selected page
      if (this.pagination.currentPage !== targetPage) {
        this.pagination.currentPage = targetPage;
      } else {
        // prevent "NavigationDuplicated" error from router
        return;
      }
      this.$router.push({ name: this.$router.name, query: Object.assign({ page: this.pagination.currentPage }, this.queryParams) });
    },

    useFallbackImage(index) {
      // missing movie poster thumbnail, fallback to large poster
      if (!this.posterFallbackTries[this.movieList[index].MovieID]) {
        // attempt 1: poster thumbnail is missing, use large poster
        this.$set(this.movieList[index], 'thumbnailSrc', `/assets/sample/${this.movieList[index].MovieID}/str.jpg`);
        this.posterFallbackTries[this.movieList[index].MovieID] = true;
      } else {
        // attempt 2: large poster is missing, use no-image placeholder
        this.$set(this.movieList[index], 'thumbnailSrc', '/img/common/now_printing_landscape.png');
      }
    },

    toggleWatchLater(movieId, movieDateId, isWatchLaterChecked, index) {
      if (isWatchLaterChecked) {
        this.deleteMovieFromPlaylist(this.watchLaterPlaylistId, movieId, movieDateId);
        this.$set(this.movieList[index], 'isWatchLaterChecked', false);
      } else {
        this.addMovieToPlaylist(this.watchLaterPlaylistId, movieId, movieDateId);
        this.$set(this.movieList[index], 'isWatchLaterChecked', true);
      }
    },

    addMovieToPlaylist(playlistId, movieId, movieDateId) {
      bfAPI.addMovieToPlaylist(playlistId, movieId).then((response) => {
        if (response.status === 200) {
          // show notification
          EventBus.$emit('notification:event:generic', {
            text: this.$t('ntf_add_playlist_0') + this.watchLaterName + this.$t('ntf_add_playlist_1'),
            duration: 3000,
          });

          // analytics
          this.$analytics.trackEvent('Playlist', 'Add Movie to "Watch Later"', movieDateId);
          bfAPI.getMovieDetail(movieDateId).then((res) => {
            Analytics.sendCustomEvent('playlist', {
              playlist_action: 'Add Movie to Playlist',
              playlist_type: 'Default',
              playlist_name: 'Watch Later',
              movie_id: movieDateId,
              movie_title: res.Title,
              actress_name: res.Actor,
            });
          });
        }
      });
    },

    deleteMovieFromPlaylist(playlistId, movieId, movieDateId) {
      bfAPI.deleteMovieFromPlaylist(playlistId, movieId, movieDateId).then((response) => {
        if (response.status === 200) {
          // show notification
          EventBus.$emit('notification:event:generic', {
            text: this.$t('ntf_remove_playlist_0') + this.watchLaterName + this.$t('ntf_remove_playlist_1'),
            duration: 3000,
          });

          // analytics
          bfAPI.getMovieDetail(movieDateId).then((res) => {
            Analytics.sendCustomEvent('playlist', {
              playlist_action: 'delete Movie from Playlist',
              playlist_type: 'Default',
              playlist_name: 'Watch Later',
              movie_id: movieDateId,
              movie_title: res.Title,
              actress_name: res.Actor,
            });
          });
        }
      });
    },

    // these following 3 functions are for hover effect on image when mouse over movie title/data section
    hoverOn(mid) {
      this.hoverId = mid;
    },

    hoverOff() {
      this.hoverId = '';
    },

    hoverActive(mid) {
      return this.hoverId === mid;
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    page() {
      return (this.$route.query.page) ? this.$route.query.page : 1;
    },
    offset() {
      return (this.page - 1) * this.listSize;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getMovieListForUser(newVal);
    },
    listSize(newVal, oldVal) {
      if (newVal !== oldVal) this.getMovieListForUser(this.userTypeID);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/css/pagination';
</style>
